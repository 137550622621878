import React from "react"
import { makeStyles } from "@material-ui/styles"
// import Link from "../../Link"
import styles from "./styles"

// import gaLogo from "../../../images/header-ga-logo.png"
// import gaMember from "../../../images/header-member.png"

const useStyles = makeStyles(theme => styles(theme))

export default function AAGHeader() {
  const classes = useStyles()

  return (
    <div className={classes.aagHeader}>
      {/* <Link to="https://groupauto.co.uk/" className="ga-logo">
        <img
          src={gaLogo}
          alt="Group Auto UK & Ireland"
          width="160"
          height="33"
        />
      </Link>

      <Link to="https://groupauto.co.uk/" className="ga-member">
        <img
          src={gaMember}
          alt="A member of Group Auto"
          width="230"
          height="19"
        />
      </Link> */}

      <p className="ga-byline">member of group auto</p>
    </div>
  )
}
