import React from "react"
import { makeStyles } from "@material-ui/styles"
// import Link from "../../Link"
import styles from "./styles"

// import gaLogo from "../../../images/uan-logo.png"
// import gaMember from "../../../images/header-member-uan.png"

const useStyles = makeStyles(theme => styles(theme))

export default function AAGHeader() {
  const classes = useStyles()

  return (
    <div className={classes.uanHeader}>
      {/* <Link to="https://unitedaftermarket.net/" className="ga-logo">
        <img
          src={gaLogo}
          alt="United Aftermarket Network An Alliance Automitve Group Company"
        />
      </Link> */}

      {/* <Link to="https://unitedaftermarket.net/" className="ga-member">
        <img src={gaMember} alt="A member of United Aftermarket Network" />
      </Link> */}

      <p className="uan-byline">member of united aftermarket network</p>
    </div>
  )
}
