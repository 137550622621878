import React from "react"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import styles from "./styles"

import gaLogo from "../../../images/footer-ga-logo.png"

const useStyles = makeStyles(theme => styles(theme))

export default function AAGFooter() {
  const classes = useStyles()

  return (
    <div className={classes.aagFooter}>
      <div className="wrapper">
        <Link
          to="https://groupauto.co.uk/"
          className="ga-logo"
          alt="Group Auto"
        >
          <img
            src={gaLogo}
            alt="Group Auto UK & Ireland"
            width="245"
            height="70"
          />
        </Link>
      </div>
    </div>
  )
}
