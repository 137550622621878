/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Meta from "./Meta"
import "./layout.scss"
import { graphql, useStaticQuery } from "gatsby"

import Header001 from "./Headers/Header001"
import Header002 from "./Headers/Header002"
import Header003 from "./Headers/Header003"
import Footer001 from "./Footers/Footer001"
import Footer002 from "./Footers/Footer002"

import AAGHeader from "./Headers/AAGHeader"
import AAGFooter from "./Footers/AAGFooter"

import UANHeader from "./Headers/UANHeader"
import UANFooter from "./Footers/UANFooter"

import GlobalAlert from "./GlobalAlert"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query FindFonts {
      wp {
        themeOptions {
          ThemeOptions {
            fontSet
            fonts {
              subHeaderFont
              headerFont
              bodyFont
            }
            primary {
              fieldGroupName
              primaryColour
              onPrimary
              primaryHighlight
            }
            secondary {
              secondaryColour
              onSecondary
              secondaryHighlight
              fieldGroupName
            }
            tertiary {
              tertiaryColour
              onTertiary
              tertiaryHighlight
              fieldGroupName
            }
            accent {
              accentColour
              onAccent
              accentHighlight
              fieldGroupName
            }
            generalColours {
              black
              bodyBackground
              fieldGroupName
              white
            }
            headerStyle
            footerStyle
          }
        }
        clientOptions {
          ClientOptions {
            aagBranded
            uanBranded
          }
        }
      }
    }
  `)

  let fonts = data.wp.themeOptions.ThemeOptions.fonts
  let fontSet = data.wp.themeOptions.ThemeOptions.fontSet

  const [loaded, setLoaded] = React.useState()

  React.useEffect(() => {
    setLoaded(true)
  }, [])

  if (fontSet !== "custom") {
    if (fontSet === "modern") {
      fonts.headerFont = "IBM Plex Sans"
      fonts.subHeaderFont = "IBM Plex Sans"
      fonts.bodyFont = "Open Sans"
    } else if (fontSet === "retro") {
      fonts.headerFont = "MuseoModerno"
      fonts.subHeaderFont = "MuseoModerno"
      fonts.bodyFont = "Roboto"
    } else if (fontSet === "heritage") {
      fonts.headerFont = "Merriweather"
      fonts.subHeaderFont = "Merriweather"
      fonts.bodyFont = "Merriweather"
    } else if (fontSet === "friendly") {
      fonts.headerFont = "Quicksand"
      fonts.subHeaderFont = "Quicksand"
      fonts.bodyFont = "Rubik"
    } else if (fontSet === "motorsharp") {
      fonts.headerFont = "Oswald"
      fonts.subHeaderFont = "Oswald"
      fonts.bodyFont = "Raleway"
    } else if (fontSet === "geomotor") {
      fonts.headerFont = "Poppins"
      fonts.subHeaderFont = "Poppins"
      fonts.bodyFont = "Lato"
    }
  }

  return (
    <>
      <CssBaseline />

      <style>
        {"html{--background-colour:" +
          data.wp.themeOptions.ThemeOptions.generalColours.bodyBackground +
          ";--background:" +
          data.wp.themeOptions.ThemeOptions.generalColours.bodyBackground +
          ";--black:" +
          data.wp.themeOptions.ThemeOptions.generalColours.black +
          ";--white:" +
          data.wp.themeOptions.ThemeOptions.generalColours.white +
          ";--primary-colour:" +
          data.wp.themeOptions.ThemeOptions.primary.primaryColour +
          ";--primary:" +
          data.wp.themeOptions.ThemeOptions.primary.primaryColour +
          ";--primary-highlight:" +
          data.wp.themeOptions.ThemeOptions.primary.primaryHighlight +
          ";--on-primary:" +
          data.wp.themeOptions.ThemeOptions.primary.onPrimary +
          ";--secondary-colour:" +
          data.wp.themeOptions.ThemeOptions.secondary.secondaryColour +
          ";--secondary:" +
          data.wp.themeOptions.ThemeOptions.secondary.secondaryColour +
          ";--secondary-highlight:" +
          data.wp.themeOptions.ThemeOptions.secondary.secondaryHighlight +
          ";--on-secondary:" +
          data.wp.themeOptions.ThemeOptions.secondary.onSecondary +
          ";--tertiary-colour:" +
          data.wp.themeOptions.ThemeOptions.tertiary.tertiaryColour +
          ";--tertiary:" +
          data.wp.themeOptions.ThemeOptions.tertiary.tertiaryColour +
          ";--tertiary-highlight:" +
          data.wp.themeOptions.ThemeOptions.tertiary.tertiaryHighlight +
          ";--on-tertiary:" +
          data.wp.themeOptions.ThemeOptions.tertiary.onTertiary +
          ";--accent-colour:" +
          data.wp.themeOptions.ThemeOptions.accent.accentColour +
          ";--accent:" +
          data.wp.themeOptions.ThemeOptions.accent.accentColour +
          ";--accent-highlight:" +
          data.wp.themeOptions.ThemeOptions.accent.accentHighlight +
          ";--on-accent:" +
          data.wp.themeOptions.ThemeOptions.accent.onAccent +
          ";}html,body{max-width:100%;overflow-x:hidden;}body{position:relative;background-color:" +
          data.wp.themeOptions.ThemeOptions.generalColours.bodyBackground +
          "}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"}
      </style>
      <link
        rel={loaded ? "stylesheet" : "preload"}
        href={
          "https://fonts.googleapis.com/css2?family=" +
          fonts.headerFont.replace("/ /g", "+") +
          ":wght@100;300;400;500;700;900&display=swap"
        }
        as="style"
      />
      <link
        rel={loaded ? "stylesheet" : "preload"}
        href={
          "https://fonts.googleapis.com/css2?family=" +
          fonts.subHeaderFont.replace("/ /g", "+") +
          ":wght@100;300;400;500;700;900&display=swap"
        }
        as="style"
      />
      <link
        rel={loaded ? "stylesheet" : "preload"}
        href={
          "https://fonts.googleapis.com/css2?family=" +
          fonts.bodyFont.replace("/ /g", "+") +
          ":wght@100;300;400;500;700;900&display=swap"
        }
        as="style"
      />
      <link
        rel={loaded ? "stylesheet" : "preload"}
        href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"
        as="style"
      />
      <Meta meta={props.meta} title={props.title} path={props.path} />

      {data.wp.clientOptions.ClientOptions.aagBranded && <AAGHeader />}
      {data.wp.clientOptions.ClientOptions.uanBranded && <UANHeader />}

      {data.wp.themeOptions.ThemeOptions.headerStyle === "header-001" && (
        <Header001 />
      )}
      {data.wp.themeOptions.ThemeOptions.headerStyle === "header-002" && (
        <Header002 />
      )}
      {data.wp.themeOptions.ThemeOptions.headerStyle === "header-003" && (
        <Header003 />
      )}

      <main>{props.children}</main>

      {data.wp.clientOptions.ClientOptions.aagBranded && <AAGFooter />}
      {data.wp.clientOptions.ClientOptions.uanBranded && <UANFooter />}

      {data.wp.themeOptions.ThemeOptions.footerStyle === "footer-001" && (
        <Footer001 />
      )}

      {data.wp.themeOptions.ThemeOptions.footerStyle === "footer-002" && (
        <Footer002 />
      )}

      <GlobalAlert />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
}

export default Layout
