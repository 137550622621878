import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import ReactHtmlParser from "react-html-parser"
import styles from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"

const useStyles = makeStyles(theme => styles(theme))

export default function Header003(props) {
  const [open, setOpen] = React.useState(false)
  const [openSub, setOpenSub] = React.useState("")

  const handleOpen = () => {
    setOpen(!open)
  }

  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query Header003Items {
      allWpMenu(filter: { locations: { in: HEADER_MENU } }) {
        edges {
          node {
            id
            name
            slug
            menuItems {
              nodes {
                id
                title
                url
                path
                label
                target
                order
                parentId
                childItems {
                  nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                  }
                }
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            siteLogo {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 285
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
            headerOverrides {
              headerBackgroundColour
              headerLinkColour
              dropdownBackgroundColour
              dropdownLinkColour
              contactStripBackgroundColour
              contactStripTextColour
              contactStripIconColour
            }
          }
        }
        businessInformation {
          LocationData {
            telephone
            address1
            address2
            town
            region
            postcode
          }
        }
      }
    }
  `)

  let logo = data.wp.themeOptions.ThemeOptions.siteLogo
  let overrides = data.wp.themeOptions.ThemeOptions.headerOverrides
  let business = data.wp.businessInformation.LocationData

  let image = null

  // Use the gatsby image if available
  if (logo.localFile) {
    if (logo.localFile.childImageSharp) {
      image = (
        <GatsbyImage
          image={logo.localFile.childImageSharp.gatsbyImageData}
          alt="Home"
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = <img src={logo.localFile.publicURL} alt="Home" />
    }
  } else {
    // This is a preview image
    image = <img src={logo.sourceUrl} alt="Home" />
  }

  return (
    <>
      <div
        className={classes.contactStrip}
        data-override-background={
          overrides.contactStripBackgroundColour !== "accent"
            ? overrides.contactStripBackgroundColour
            : null
        }
      >
        <div className="wrapper">
          <Link
            to={"tel:" + business.telephone}
            data-override-color={
              overrides.contactStripTextColour !== "on-accent"
                ? overrides.contactStripTextColour
                : null
            }
          >
            <PhoneIcon
              data-override-color={
                overrides.contactStripIconColour !== "on-accent"
                  ? overrides.contactStripIconColour
                  : null
              }
            />
            {business.telephone}
          </Link>
          <span
            data-override-color={
              overrides.contactStripTextColour !== "on-accent"
                ? overrides.contactStripTextColour
                : null
            }
          >
            <RoomIcon
              data-override-color={
                overrides.contactStripIconColour !== "on-accent"
                  ? overrides.contactStripIconColour
                  : null
              }
            />
            {business.address1}, {business.address2}, {business.town},{" "}
            {business.region}, {business.postcode}
          </span>
        </div>
      </div>
      <div
        className={classes.header003}
        data-override-background={
          overrides.headerBackgroundColour !== "primary"
            ? overrides.headerBackgroundColour
            : null
        }
        open={open}
      >
        <div className="wrapper">
          <div className="site-logo">
            <Link to="/">{image}</Link>
          </div>

          <button
            className={`menu-trigger ${
              overrides.headerLinkColour !== "on-primary"
                ? overrides.headerLinkColour
                : ""
            } `}
            onClick={handleOpen}
            onKeyDown={handleOpen}
            aria-label="Open Menu"
          >
            <span />
          </button>

          <div className="site-nav">
            {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => {
              // console.log(section);
              if (section.childItems.nodes.length > 0) {
                return (
                  <div
                    className="sub-menu"
                    key={section.id}
                    data-active={openSub === section.id ? true : false}
                  >
                    <button
                      onClick={() => {
                        if (openSub === section.id) {
                          setOpenSub("")
                        } else {
                          setOpenSub(section.id)
                        }
                      }}
                      data-override-color={
                        overrides.headerLinkColour !== "on-primary"
                          ? overrides.headerLinkColour
                          : null
                      }
                      onKeyDown={() => setOpenSub(section.id)}
                    >
                      {ReactHtmlParser(section.label)} <ArrowDropDownIcon />
                    </button>
                    <div
                      className="sub-items-wrap"
                      data-override-background={
                        overrides.dropdownBackgroundColour !== "on-primary"
                          ? overrides.dropdownBackgroundColour
                          : null
                      }
                    >
                      <div className="sub-items">
                        {section.childItems.nodes.map(child => {
                          return (
                            <Link
                              key={child.id}
                              to={child.path}
                              data-override-color={
                                overrides.dropdownLinkColour !== "primary"
                                  ? overrides.dropdownLinkColour
                                  : null
                              }
                            >
                              {ReactHtmlParser(child.label)}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              } else if (section.parentId == null) {
                return (
                  <Link
                    key={section.id}
                    to={section.path}
                    data-override-color={
                      overrides.headerLinkColour !== "on-primary"
                        ? overrides.headerLinkColour
                        : null
                    }
                  >
                    {ReactHtmlParser(section.label)}
                  </Link>
                )
              }
              return null
            })}
          </div>
        </div>
      </div>
    </>
  )
}
