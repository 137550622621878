import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import styles from "./styles"

import gaLogo from "../../../images/uan-logo.png"

const useStyles = makeStyles(theme => styles(theme))

export default function AAGFooter() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query UANFooter {
      wp {
        clientOptions {
          ClientOptions {
            uanFooterLogo {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 475, formats: [WEBP], quality: 90)
                }
              }
            }
          }
        }
      }
    }
  `)

  let image = null

  let logo = data.wp.clientOptions.ClientOptions.uanFooterLogo

  if (logo.localFile) {
    if (logo.localFile.childImageSharp) {
      image = (
        <img
          src={
            logo.localFile.childImageSharp.gatsbyImageData.images.fallback.src
          }
          width="475"
          height={logo.localFile.childImageSharp.gatsbyImageData.height}
          alt="Home"
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      image = <img src={logo.localFile.publicURL} alt="Home" width="475" />
    }
  } else {
    // This is a preview image
    image = <img src={logo.sourceUrl} alt="Home" width="475" />
  }

  return (
    <div className={classes.aagFooter}>
      <div className="wrapper">
        <Link to="https://unitedaftermarket.net/" className="ga-logo">
          {image && image}
          {!image &&
            <img src={gaLogo} alt="UAN logo" width="245" height="70" />
          }
        </Link>

        {/* 
        <Link to="https://unitedaftermarket.net/" className="ga-member-logo" >
          {image}
        </Link> */}
      </div>
    </div>
  )
}
